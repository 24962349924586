@import "../template/mixin_templates";
@import "../variables";
@import "../../foundation/functions";

/* Général */
body{
    background-color: $body_background_color;
    color: $body_texte_color;
}
a{
    color: $link_color;
}
a:hover, a:active{
    color: $link_hover_color;
}

h2, .h2, .single-article .titre-article h1{
    color: $titlesection_color;
    background-color: $titlesection_background_color;
    a, a:hover{
        color: inherit !important;
    }
}
h2::before,
.h2::before,
.single-article .titre-article h1::before,
.single-article .titre-article .h1::before{
    border-color: $titlesection_before_bordercolor;
}
h2::after,
.h2::after,
.single-article .titre-article h1::after,
.single-article .titre-article .h1::after{
    border-color: $titlesection_after_bordercolor;
}


/* Header */
header{
    background-color: $header_background_color;
}
.slogan{
    color: $slogan_color;
}

nav#nav-principal{
    .navigation_button_p{
        background-color: $nav_background_color;
        color: $nav_color;
        &::before{
            border-color: $nav_a_before_bordercolor;
        }
        &::after{
            border-color: $nav_a_after_bordercolor;
        }
        &.actif, &:hover{
            background-color:  $nav_actif_background_color;
            &::before{
                border-color: $nav_actif_before_bordercolor;
            }
            &::after{
                border-color: $nav_actif_after_bordercolor;
            }
            > .navigation_link_p{
                color: $nav_actif_color;
            }
        }
    }
    .navigation_link_p{
        color: $nav_color;
    }
    .sous_navigation_link_p {
        color: $sousnav_color;
    }
    .sous_navigation_button_p {
        background-color: $sousnav_background_color;
        &.actif, &:hover{
            background-color: $sousnav_hover_background_color;
            > .sous_navigation_link_p{
                color: $sousnav_hover_color;
            }
        }
    }
}

/* Content */
.content_p{
    background-color: $content_background_color;
}
.content_p .nav-static-breadcrumb a, #fiche-produit nav.secondaire a{
    border-bottom-color: $navarianne_borderbottom_color;
    color:$navarianne_borderbottom_color;
}
.content_p .nav-static-breadcrumb a:hover, #fiche-produit nav.secondaire a:hover{
    border-bottom-color: $navarianne_borderbottom_hover_color;
}
.content_p .nav-static-breadcrumb a.actif, #fiche-produit nav.secondaire a.actif, .fil-dariane ul li:last-child, .fil-dariane ul li:last-child a{
    color: $navarianne_actif_color;
    background-color: $navarianne_actif_background_color;
    border-color: $navarianne_actif_bordercolor;
}
a.bouton, .button, a.button, input[type=submit], a.addmsglo, .addbasket, .diaporama a{
    background-color: $button_background_color;
    border-color: $button_border_color;
    color: $button_color;
}
.formulaires_perso input[type=submit]{
    background-color: $button_background_color !important;
    border-color: $button_border_color !important;
    color: $button_color !important;
}
.content_p a.bouton:hover, .button:hover, a.addmsglo:hover, .addbasket:hover, .diaporama a:hover{
    color: $button_hover_color;
    background-color: $button_hover_background_color;
}
.formulaires_perso input[type=submit]:hover{
    color: $button_hover_color !important;
    background-color: $button_hover_background_color !important;
}

.formulaires_perso div.center, .form-newsletter .center{
    color: $formperso_titre_color;
}

/* accueil */
.slide-text > p, .slide-text > a{
    color: $slidetext_color;
    background-color: $slidetext_background_color;
}


/* boutique */
.produit_etiquette{
    background-color: $etiquette_background_color;
}
.produit_etiquette h3,
.produit_etiquette .h3{
    color: $etiquette_color;
}
.remise{
    color: $etiquette_color;
    background-color: $etiquette_background_color;
}
.produits .mask .lien-mask{
    color: $produits_mask_color;
    //background-color: $produits_mask_background_color;
}
.produits .zoom {
    color: $produits_zoom_color;
    background-color: $produits_zoom_background_color;
}
.produits .zoom:hover{
    color: $produits_zoom_color;
    background-color: $produits_zoom_background_color;
}
.prix{
    color: $prix_color;
}
.prix .oldprix{
    color: $oldprix_color;
}

#fiche-produit .remise-produit{
    background-color: $ficheproduit_triangleremise_bordercolor;
}
#fiche-produit .wrap-description .prix span.oldprix{
    color: $ficheproduit_oldprix_color;
}
.search-bar .search-bar-icon button{
    color: $searchbaricon_color;
    background-color: $searchbaricon_background_color;
}
.search-bar .search-bar-icon button:hover{
    color: $searchbaricon_background_color;
    background-color: $searchbaricon_color;
}
#fiche-produit .wrap-images .previews .previous, #fiche-produit .wrap-images .previews .next{
    color: $ficheproduit_fleches_color;
}


/* galerie */
.list-photos .galerie{
    background-color: $photo_mask_background_color;
    .mask{
        background-color: $photo_mask_background_color;
    }
    p{
        color: $photo_description_color;
    }
    .addcommentaires{
        color: $photo_commentaires_color;
    }
}

/* livre d'or */
.message_lo, .message_lo a {
    color: $msglo_texte_color;
}
.message_lo h4,
.message_lo .h4{
    color: $msglo_h4_color;
    background-color: $msglo_h4_background_color;
}
.message_lo .note{
    color: $msglo_note_color;
    background-color: $msglo_h4_background_color;
}

/* contact */
form input:focus, form textarea:focus, form select:focus, #fiche-produit form input:focus,
#fiche-produit form textarea:focus, #fiche-produit form select:focus{
    box-shadow: $form_focus_shadow;
    border-color: $form_focus_bordercolor;
}


/* Footer */
.footer_p{
    color: $footer_color;
    background-color: $footer_background_color;
}
.footer_p .mention a, .footer_p a{
    color: $footer_color;
}

/* blog */

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: $background_blog_menu;
    border-color: $background_blog_menu;
}
.pagination > li > a, .pagination > li > span{
    color:$background_blog_menu;
}
.row.two-column-blog .apercu-article h2.titre-article, .single-article .titre-article h1, .apercu-article h2.titre-article-no-image,
.row.two-column-blog .apercu-article .h2.titre-article, .single-article .titre-article .h1, .apercu-article .h2.titre-article-no-image{
    background:$background_blog_h2;
}
.pagination.pull-right li.active a{
    color:$white;
    background-color:$background_blog_menu;
}

/* Template Panier */

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
    &.template-panier-2{
        background-color:$panier_background_color_2;
        #panier{
            border-color:$panier_background_color_2;
            #header-panier{
                color:$panier_texte_color_2;
            }
            #paniercontent{
                border-color:$panier_background_color_2;
                background-color: #fff;
                color:$panier_background_color_2;
                #paniertable, .total-live{
                    color:$panier_background_color_2;
                }
            }
        }
    }
}
.template_boutique .produits {
    border-bottom: 1px solid $produits_zoom_background_color;
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $titlesection_background_color;
    border-color: $titlesection_background_color;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $titlesection_background_color;
    color: $titlesection_background_color;

    a, span {
        color: $titlesection_background_color;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}