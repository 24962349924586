
/* Général */
$body_background_color : #00ade0;
$body_texte_color: #000;
$link_color : #000;
$link_hover_color : #0076be;

$titlesection_color : #fff;
$titlesection_background_color : #0076be;
$titlesection_before_bordercolor : transparent #0076be transparent transparent;
$titlesection_after_bordercolor : transparent transparent transparent #0076be;

/* Header */
$header_background_color : #fff; 
$slogan_color : #000;

$nav_color : #fff;
$nav_background_color : #000;
$nav_a_before_bordercolor : transparent black transparent transparent;
$nav_a_after_bordercolor : transparent transparent transparent black;
$sousnav_color: #fff;
$sousnav_background_color :#0076be;
$sousnav_hover_color : #0076be;
$sousnav_hover_background_color : #fff;
$nav_actif_color : #fff; 
$nav_actif_background_color : #0076be;
$nav_actif_before_bordercolor : transparent #0076be transparent transparent;
$nav_actif_after_bordercolor : transparent transparent transparent #0076be;

/* Content */
$content_background_color : #fff;
$navarianne_borderbottom_color : #000;
$navarianne_borderbottom_hover_color : #0076be;
$navarianne_actif_color : #0076be;
$navarianne_actif_background_color : #fff;
$navarianne_actif_bordercolor : #0076be;
$button_background_color : #0076be;
$button_color : #fff;
$button_border_color: #0076be;
$button_hover_color : #0076be;
$button_hover_background_color : #fff;

$formperso_titre_color: #0076be;


/* accueil */
$slidetext_color: #000;
$slidetext_background_color : #fff;


/* boutique */
$etiquette_color : #fff;
$etiquette_background_color : #00ade0;
$produits_mask_color : #fff;
$produits_mask_background_color : rgba(0, 173, 224,0.6);
$produits_zoom_color : #fff;
$produits_zoom_background_color : #0076be;
$produits_zoom_hover_color : #0076be;
$produits_zoom_hover_background_color : #fff;
$oldprix_color : #8c8c8c;
$prix_color: #0076be;

$ficheproduit_triangleremise_bordercolor: #0076be;
$ficheproduit_oldprix_color: #8c8c8c;
$ficheproduit_fleches_color: #0076be;

$searchbaricon_color : #fff;
$searchbaricon_background_color :#0076be;


/* galerie */
$photo_mask_background_color : #0076be;
$photo_description_color : #fff;
$photo_description_background_color : #fff;
$photo_commentaires_color : #fff;

/* livre d'or */
$msglo_texte_color: #000; 
$msglo_h4_color: #000;
$msglo_h4_background_color: #71D6F4;
$msglo_note_color: #fff;

/* contact */
$form_focus_shadow: 0 0 5px #71D6F4;
$form_focus_bordercolor: #71D6F4;


/* Footer */
$footer_color : #fff;
$footer_background_color : rgb(34, 34, 34); 

/* Blog */
$background_blog_menu :  #0076be;
$background_blog_h2 : #0076be;
$white : #fff;

/* Panier template 2 */
$panier_background_color_2:#0076be;
$panier_texte_color_2:#fff;
/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/732series/declinaison";  